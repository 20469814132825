export const UUID_PACKAGE_PARAM_NAME = "package_id";
export const UUID_SERVICE_PARAM_NAME = "service_id";
export const UUID_STUDENT_PARAM_NAME = "student_id";
export const AUTH_TOKEN_PARAM_NAME = "auth_token";

export const POSTE_ITALIANE = "POSTE_ITALIANE";
export const PEARSON_COURSE = "PEARSON_COURSE";
export const getQueryParam = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};