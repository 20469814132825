import React, { useState } from 'react';

export interface IThankYouPageProps { }

const ThankYouPage: React.FunctionComponent<IThankYouPageProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const goInApp = async () => {
        setIsLoading(true);

        try {
            // window.location.href = 'https://habacuspreprod.page.link/qbvQ';
            window.location.href = 'https://mobile.habacus.com/tHrB';
        } catch (e) {
            console.log(e);
            window.location.href = 'https://appdownload.habacus.com';
        }
        finally {
            setIsLoading(false);
        }
    }

    return (<main className="ThankYouPage">

        <div className=' mt-5'>
            <h1>Grazie</h1><br />
            <div className=''>
                <p>
                    I risultati sono stati caricati correttamente.
                </p>
                <p>
                    Adesso torna in App Habacus e consulta i percorsi di studio utili a svolgere le professioni che sono risultate le più affini.
                </p>

                {/* <p>A PRESTO</p> */}
            </div>
            {/* <div className='my-3 '>
                <Button id="goInApp" className="blueButt customButt" onClick={goInApp} disabled={isLoading}>
                    <div className='flexButt'>
                        <img src={external_link} alt="" />
                        <div className='saveAndGo'>
                            <div>TORNA</div>
                            <div>IN APP</div>
                        </div>
                    </div>
                </Button>

            </div> */}

        </div>

    </main>);
};

export default ThankYouPage;