import React from 'react';
import { getQueryParam } from '../utils/utils';

export interface ITestInBrowserPageProps { }

const TestInBrowserPage: React.FunctionComponent<ITestInBrowserPageProps> = (props) => {
    const mediumFromUrl = getQueryParam('medium');

    let homeStyle = {};

    if (mediumFromUrl === 'app') {
        homeStyle = {
            minHeight: '100vh'
        };
    }

    return (<main className="TestInBrowserPage" style={homeStyle}>

        <h1 className='mb-5'>Controlla la tua casella mail</h1>
        <p className='alignWithMediaQuery'>
            Ti abbiamo inviato una e-mail con il link al test. <br />
            <small>Se non la ricevi, controlla anche nella cartella spam.</small>
        </p>

    </main>);
};

export default TestInBrowserPage;