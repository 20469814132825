import icon from '../assets/ib.png';
import question from '../assets/icons8-question-64.png';
import logo from '../assets/lb.png';

export interface IHeaderProps { }

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
    return (<header className="Header">
        <div className="logo">

            <img
                src={logo}
                alt="Habacus Icon"
                style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                className="desktop"
            />
            <img
                src={icon}
                alt="Habacus Icon"
                style={{ minHeight: '40px', maxHeight: '40px' }}
                className="mobile"
            />


        </div>
        <a href="mailto:info@habacus.com" >
            <div className="support flexRight">

                <div className='desktop'>
                    Hai bisogno di aiuto? Contattaci
                </div>

                <img
                    src={question}
                    alt="Habacus Icon"
                    style={{ minHeight: '40px', maxHeight: '40px', paddingLeft: '1vh' }}
                    className=""
                />

            </div>
        </a>
    </header>);
};

export default Header;