import logoWhite from '../assets/logo_w.svg';
import send from '../assets/send.svg';
import facebook from '../assets/social_facebook.svg';
import instagram from '../assets/social_instagram.svg';
import linkedin from '../assets/social_linkedin.svg';
import youtube from '../assets/social_youtube.svg';


export interface IFooterProps { }

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
    return (<footer className="Footer">
        <div className="content">
            <div className="logo_w_container pb-5">
                <a href="https://habacus.com/it" target="_blank" rel="noopener noreferrer" >
                    <img src={logoWhite} alt="" />
                </a>
            </div>
            <div className="main_text pb-4">
                Habacus è una data company ad impatto sociale che sostiene la formazione post-diploma; un integratore di sistema che mette in rete studenti, enti formativi, finanza privata e istituzioni pubbliche.
            </div>
            <div className="social_links_container pb-4">
                <a href="https://www.facebook.com/habacus.srl/" target="_blank" rel="noopener noreferrer" >
                    <img src={facebook} alt="social_facebook" />
                </a>
                <a href="https://www.instagram.com/habacus_ita/" target="_blank" rel="noopener noreferrer" >
                    <img src={instagram} alt="social_instagram" />
                </a>
                <a href="https://www.linkedin.com/company/h-abacus/" target="_blank" rel="noopener noreferrer" >
                    <img src={linkedin} alt="social_linkedin" />
                </a >
                <a href="https://www.youtube.com/channel/UCmOWrnZDTuuvOYDrQdDZ2Cw" target="_blank" rel="noopener noreferrer" >
                    <img src={youtube} alt="social_youtube" />
                </a >
            </div >
            <div className="contact_us pb-4">

                <a href="mailto:info@habacus.com" className="button_contact_us">
                    <span className="button_text">
                        Contattaci
                    </span>
                    <span className="arrow_container">
                        <img src={send} alt="" />
                    </span>
                </a>

            </div>

            <div className="info">

                Habacus srl - Sede legale: Via Filippo Sassetti, 32, 20124 Milano (MI), Italia <br />
                C.F. e numero di iscrizione presso il Registro delle Imprese di Milano Monza Brianza Lodi 04906910262 <br /> <br />
                Habacus srl © 2022


            </div>



        </div>
    </footer>);
};

export default Footer;