import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import icon_attention from '../assets/attention.png';
import external_link from '../assets/icons8-link-esterno-squadrato-96.png';
import envelope from '../assets/icons8-mail-50.png';
import floppyNeon from '../assets/icons8-save-100-neon.png';
import { ServiceStatus, getMotivaLink, updateServiceStatus, updateStudentEvent, uploadTestPdf } from '../utils/api';
import { PEARSON_COURSE, getQueryParam } from '../utils/utils';

export interface IHomePageProps { }

const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {

    interface StudentEvent {
        category: string,
        action: string,
        label: string,
        type?: string,
        value?: string,
    }

    interface Package {
        name: string,
    }

    interface DataToPass {
        uuid: string,
        name: string,
        surname: string,
        email: string,
        lastEvent: StudentEvent,
        package: Package,
    }

    let navigate = useNavigate();
    const { state } = useLocation();
    const { dataToPass } = state || {};
    const { paramsFromUrl } = state || {};

    const [isLoading, setIsLoading] = useState(false);
    const [showUploadPdfModal, setShowUploadPdfModal] = useState(false);
    const [showUploadPdfModalNoPdf, setShowUploadPdfModalNoPdf] = useState(false);
    const [selectedFile, setSelectedFile] = React.useState<File | null>();

    const [question_when, setQuestion_when] = useState(true);
    const [question_where, setQuestion_where] = useState(true);
    const [is_upload_motiva_required, setIs_upload_motiva_required] = useState(false);
    const [user_can_choose_professions, setUser_can_choose_professions] = useState(false);


    const [error, setError] = useState("");

    const [lastEvent, setLastEvent] = useState<any>();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(e?.currentTarget?.files?.[0])
    }
    const languageFromUrl = getQueryParam('lang');
    const mediumFromUrl = getQueryParam('medium');

    enum StepToRender {
        NONE,
        WHEN,
        WHERE,
        TEST,
        PROFESSIONS
    }
    const [stepToRender, setStepToRender] = useState<StepToRender>(StepToRender.NONE);

    useEffect(() => {

        if (dataToPass) {

            setLastEvent(dataToPass.lastEvent);

            setQuestion_when(dataToPass.service.meta.question_when);
            setQuestion_where(dataToPass.service.meta.question_where);
            setIs_upload_motiva_required(dataToPass.service.meta.is_upload_motiva_required);
            setUser_can_choose_professions(dataToPass.service.meta.user_can_choose_professions);
        }
    }, [])

    useEffect(() => {
        chooseWhatToRender();
    }, [lastEvent])


    // //TODO From response
    // const question_when = true;
    // const question_where = true;
    // const is_upload_motiva_required = true;
    // let user_can_choose_professions = false;

    // NEEDED TO CHECK IF THE USER ALREADY ARRIVED ON THE MAIN PAGE AFTER THE 2 QUESTIONS. IF YES HE HAS MAIN STATUS IN SERVICES, IF NOT WE START WITH THE FIRST QUESTION
    const chooseWhatToRender = () => {

        if (!lastEvent || !lastEvent.action) {
            if (question_when === true) {
                setStepToRender(StepToRender.WHEN);
            } else if (question_where === true && mediumFromUrl === 'app') {
                setStepToRender(StepToRender.WHERE);
            } else {
                setStepToRender(StepToRender.TEST);
            }

        } else if (lastEvent.action === 'CHOICE' && lastEvent.type === 'WHEN') {
            if (question_where === true && mediumFromUrl === 'app') {
                setStepToRender(StepToRender.WHERE);
            } else {
                setStepToRender(StepToRender.TEST);
            }

        } else if (lastEvent.action === 'CHOICE' && lastEvent.type === 'WHERE') {
            if (mediumFromUrl === 'app' && !localStorage.getItem('whereIsClicked')) { // todo check nuovo query param
                setStepToRender(StepToRender.WHERE);
            } else {
                setStepToRender(StepToRender.TEST);
            }
            return;

        } else if (lastEvent.action === 'START') {
            if (question_where === true && mediumFromUrl === 'app' && !localStorage.getItem('whereIsClicked')) {
                // every time the user starts the service we have to ask
                // if he wants to continue in mobile or in dekstop
                setStepToRender(StepToRender.WHERE);
            } else {
                setStepToRender(StepToRender.TEST);
            }
            return;

        } else if (lastEvent.action === 'END') {
            if (user_can_choose_professions === true) {
                navigate('/professions', { state: { dataToPass, paramsFromUrl } });
            } else {
                navigate('/thank-you');
            }

        } else if (lastEvent.action === 'CHOICE' && lastEvent.type === 'PROFESSIONS') {
            navigate('/thank-you');

        } else {
            console.log(lastEvent);
            navigate('/thank-you');
        }
    }

    const openTestInNewPage = async () => {
        setIsLoading(true);

        try {

            let resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "START"
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }
            setLastEvent(resp.data);

            resp = await updateServiceStatus(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                ServiceStatus.EXECUTING);

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }

            resp = await getMotivaLink(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl);

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }


            const html = resp.data.html;


            setTimeout(() => {
                document.getElementById("hiddenForm")?.remove();

                const newDiv = document.createElement("div");
                newDiv.setAttribute("id", "hiddenForm");
                newDiv.innerHTML = html;
                document.getElementById("ltiDiv")?.appendChild(newDiv);

                const element: HTMLElement = document.getElementById("hiddenForm")?.querySelector("input[type=submit]") as HTMLElement;
                element?.click();
            }, 500);


        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    }


    const handleClose = () => {
        setIsLoading(true)
        setShowUploadPdfModal(false)
        setIsLoading(false)
    };
    //const handleClose = () => setShowUploadPdfModal(false);
    const handleCloseNoPdf = () => setShowUploadPdfModalNoPdf(false);

    // const errorInputPdf = document.getElementById('errorInputPdf');
    // if (errorInputPdf) {
    //     errorInputPdf.style.visibility = 'hidden';
    // }
    const closeTest = async (pdfHasToBeSent: boolean) => {

        try {
            let resp;

            if (pdfHasToBeSent) {
                const formData = new FormData();
                formData.append('file', selectedFile!);

                resp = await uploadTestPdf(
                    paramsFromUrl.uuidStudentFromUrl,
                    paramsFromUrl.uuidPackageFromUrl,
                    paramsFromUrl.uuidServicefromUrl,
                    formData);

                if (resp.status < 200 || resp.status > 299) {
                    console.log(resp.data.message);

                    if ('Failed file validation' === resp.data.message) {
                        setError('Il file caricato non è corretto.');
                        throw new Error(resp.statusText);
                    } else if ('Advisory Folder already exists' === resp.data.message) {
                        setError('Test già caricato in precedenza');
                    } else {
                        setError('Errore generico');
                        throw new Error(resp.statusText);
                    }

                }
            }

            resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "END"
                });


            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }
            setLastEvent(resp.data);

            if (user_can_choose_professions === false) {
                resp = await updateServiceStatus(
                    paramsFromUrl.uuidStudentFromUrl,
                    paramsFromUrl.uuidPackageFromUrl,
                    paramsFromUrl.uuidServicefromUrl,
                    ServiceStatus.COMPLETED);


                if (resp.status < 200 || resp.status > 299) {
                    console.log(resp);
                    alert(JSON.stringify(resp));
                    throw new Error(resp.statusText);
                }

                chooseWhatToRender();
            } else {
                navigate('/professions', { state: { dataToPass, paramsFromUrl } });
            }

        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };
    // const sleep = (ms: number | undefined) => new Promise(r => setTimeout(r, ms));
    const checkIfHorienta = async () => {
        // await sleep(5000)
        if (is_upload_motiva_required === true) {
            setShowUploadPdfModal(true);
        } else {
            setShowUploadPdfModalNoPdf(true);
        }
    }

    const handleCloseAndSendData = async () => {
        setError('');
        // navigate('/professions', { state: { dataToPass, paramsFromUrl } });
        try {
            setIsLoading(true);

            // const emptyInputPdf = document.getElementById('emptyInputPdf');
            // if (emptyInputPdf) {
            //     emptyInputPdf.style.visibility = 'hidden';
            // }
            // const wrongInputPdf = document.getElementById('wrongInputPdf');
            // if (wrongInputPdf) {
            //     wrongInputPdf.style.visibility = 'hidden';
            // }

            if (selectedFile) {
                // await loadPdf();
                closeTest(true);
            } else {
                setError('Carica il file per poter proseguire');
                // if (emptyInputPdf) {
                //     emptyInputPdf.style.visibility = 'visible';
                // }
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
        } finally {
            // setIsLoading(false);
        }
    }

    // };
    const handleCloseAndSendDataButNotThePdf = async () => {
        setError('');
        try {
            // setIsLoading(true);
            setIsLoading(true);

            closeTest(false);
        } catch (e) {
            console.error(e);
        } finally {
            // setIsLoading(false);
        }


    };


    const nowTestButton = async () => {
        console.log('nowTestButton');
        setIsLoading(true);
        try {

            const resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "CHOICE",
                    type: "WHEN",
                    value: "NOW"
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(resp.data);
                throw new Error(resp.statusText);
            }

            setLastEvent(resp.data);
            chooseWhatToRender();

        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    }

    const laterTestButton = async () => {
        console.log('laterTestButton');
        setIsLoading(true);

        try {

            const resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "CHOICE",
                    type: "WHEN",
                    value: "LATER",
                    studentPackageUUID: paramsFromUrl.uuidPackageFromUrl,
                    studentServiceUUID: paramsFromUrl.uuidServicefromUrl
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(resp.data);
                throw new Error(resp.statusText);
            }

            return navigate('/test-rimandato');

        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    }
    //II
    const inAppTestButton = async () => {

        setIsLoading(true);

        try {

            const resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "CHOICE",
                    type: "WHERE",
                    value: "APP"
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(resp.data);
                throw new Error(resp.statusText);
            }


            localStorage.setItem('whereIsClicked', '1');

            setLastEvent(resp.data);
            chooseWhatToRender();

        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }

    }

    const inWebTestButton = async () => {
        console.log('inWebTestButton');
        setIsLoading(true);

        try {

            const resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "CHOICE",
                    type: "WHERE",
                    value: "WEB"
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(resp.data);
                throw new Error(resp.statusText);
            }
            return navigate('/test-in-browser');

        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }


    }

    const renderNowOrLater = () => {


        return <>
            <h1>Vuoi fare il test di orientamento?</h1><br />
            <div className='alignWithMediaQuery'>
                <h2 className='colorBlue'><em>Scegli cosa fare:</em></h2>
                <h3>1.    Vai al Test</h3>
                <p>
                    Se decidi di iniziare il test, dovrai portarlo <b>obbligatoriamente</b> a termine per richiedere la certificazione Habacus.
                </p>
                <br />
                <h3>2.    Vai alla certificazione</h3>
                <p>
                    Potrai fare il test di orientamento in un secondo momento entro 30gg da oggi. <br />
                    In questo caso il test non è obbligatorio.
                </p>

            </div>
            <div className='my-3 buttCoupleFrame'>
                <Button id="nowTestButton" className="neonBlueButt customButt" onClick={nowTestButton}>
                    <div className='flexButt'>
                        <img src={external_link} alt="" />
                        <div className='saveAndGo'>
                            <div>VAI AL</div>
                            <div>TEST</div>
                        </div>
                    </div>
                </Button>
                <Button id="laterTestButton" className="invertedBlueNeonButt customButt" onClick={laterTestButton}>
                    <div className='flexButt'>
                        <img src={envelope} alt="" />
                        <div className='saveAndGo'>
                            <div>VAI ALLA</div>
                            <div>CERTIFICAZIONE</div>
                        </div>
                    </div>
                </Button>

            </div>
        </>
    };
    const renderMobileOrPc = () => {
        return <>
            <h1>Dove vuoi fare il test di orientamento?</h1>
            <p className='my-5 alignWithMediaQuery'>
                Se scegli di farlo su desktop, ti invieremo un link del test di orientamento via email.
            </p>
            <div className='my-3 buttCoupleFrame'>
                <Button id="inAppTestButton" className="blueNeonButt customButt" onClick={inAppTestButton} disabled={isLoading}>

                    <div className={!isLoading ? 'flexButt' : ''}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                                : <>
                                    <img src={external_link} alt="" />
                                    <div className='saveAndGo'>
                                        <div>CONTINUA SU SMARTPHONE</div>
                                    </div>

                                </>
                        }
                    </div>
                </Button>
                <div className='desktop' style={{ width: '20px' }}></div>
                <Button id="inWebTestButton" className="invertedBlueNeonButt customButt" onClick={inWebTestButton} disabled={isLoading}>

                    <div className={!isLoading ? 'flexButt' : ''}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : <>
                                    <img src={envelope} alt="" />
                                    <div className='saveAndGo'>
                                        <div>INVIA MAIL E VAI SU DESKTOP</div>
                                    </div>
                                </>
                        }
                    </div>
                </Button>

            </div>
        </>
    };
    const renderActualPage = () => {

        return <>
            <h1>Checkpoint</h1>

            <div className=''>
                <p className='alignWithMediaQuery'>Ciao <span className='fullName'>{dataToPass?.name} {dataToPass?.surname}</span>, è il tuo momento!</p>
                <p className='alignWithMediaQuery'>Ricorda:</p>
                <p className="flexCenter w-100 mt-3">
                    <img style={{ width: '25px', height: '25px', marginRight: '15px' }} src={icon_attention} alt="" />
                    <img style={{ width: '25px', height: '25px', marginRight: '15px' }} src={icon_attention} alt="" />
                    <img style={{ width: '25px', height: '25px' }} src={icon_attention} alt="" />

                </p>
                <p className='flexCenter textAlignCenter'><b>DOPO AVER CONCLUSO IL TEST DI ORIENTAMENTO, SCARICA IL PDF CON I RISULTATI E TORNA QUI</b></p>
            </div>
            <br /><br />

            <div className="my-3 buttCoupleFrame">

                <Button id="endQuizButton" className="blueNeonButt customButt" onClick={openTestInNewPage} disabled={isLoading}>
                    <div className={!isLoading ? 'flexButt' : ''}>

                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                                : <>
                                    <img src={external_link} alt="" />
                                    <div className='saveAndGo'>
                                        <div>INIZIA ORA</div>
                                    </div>

                                </>
                        }

                    </div>
                </Button>
                <div className='desktop' style={{ width: '20px' }}></div>
                <Button id="endQuizButton" className="invertedBlueNeonButt customButt" onClick={checkIfHorienta} disabled={isLoading}>
                    <div className={!isLoading ? 'flexButt' : ''}>

                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : <>
                                    <img src={floppyNeon} alt="" />
                                    <div className='saveAndGo '>
                                        <div>TERMINA IL TEST</div>
                                    </div>
                                </>
                        }

                    </div>
                </Button>
            </div>
        </>
    };

    let homeStyle = {};

    if (mediumFromUrl === 'app') {
        homeStyle = {
            minHeight: '100vh'
        };
    }

    return (
        <main className="HomePage" style={homeStyle}>

            <Modal show={showUploadPdfModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='textAlignCenter colorBlue'>
                        CARICA IL PDF
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='textAlignLeft'>
                    <p>
                        I risultati che hai ottenuto dal test di orientamento ci aiuteranno a definire il percorso di studi più adatto a te
                    </p>

                    <form action="#" method="post" id="uploadPdfForm">
                        <div className="input-group mb-3">
                            <input type="file" className="form-control" id="motivaPDF" name='motivaPDF' onChange={handleInputChange} />
                            <label className="input-group-text" htmlFor="motivaPDF">Carica</label>
                        </div>
                    </form>
                    <div className="colorRed flexBetween ">
                        <div>
                            {error}

                        </div>
                        {/* <div id="emptyInputPdf">
                            File vuoto
                        </div>
                        <div id="wrongInputPdf">
                            File errato
                        </div>
                        <div id="errorInputPdf">
                            Errore generico
                        </div> */}
                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className='invertedBlueNeonButt w-45' onClick={handleClose}
                        disabled={isLoading}>
                        Indietro
                    </Button>
                    <Button variant="primary" className='blueNeonButt w-45' onClick={handleCloseAndSendData}
                        disabled={isLoading}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : "Carica PDF"
                        }
                    </Button>
                </Modal.Footer>

            </Modal>
            <Modal show={showUploadPdfModalNoPdf} onHide={handleCloseNoPdf}>
                <Modal.Header closeButton>
                    <Modal.Title className='textAlignCenter colorBlue'>
                        HAI TERMINATO IL TEST?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='textAlignLeft'>
                    <p>
                        Se hai concluso il test clicca su 'Fine', altrimenti 'Annulla'.
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNoPdf}
                        disabled={isLoading}>
                        Annulla
                    </Button>
                    <Button variant="primary" className='bgBlue' onClick={handleCloseAndSendDataButNotThePdf}
                        disabled={isLoading}>
                        {
                            isLoading
                                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : "Fine"
                        }
                    </Button>

                </Modal.Footer>
            </Modal>
            {stepToRender === StepToRender.WHEN ? renderNowOrLater() : ''}
            {stepToRender === StepToRender.WHERE ? renderMobileOrPc() : ''}
            {stepToRender === StepToRender.TEST ? renderActualPage() : ''}
            <div id='iframeAndForm' style={{ display: 'hidden', height: '0px' }}>
                <div className="form" id="ltiDiv"></div>
            </div>
        </main>
    );
};

export default HomePage;