import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getByTaxCode } from '../utils/api';
import { UUID_PACKAGE_PARAM_NAME, UUID_SERVICE_PARAM_NAME, UUID_STUDENT_PARAM_NAME, getQueryParam } from '../utils/utils';


export interface ILoginPageProps { }

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props) => {
    //QUESTA ROBA MI SERVE E ARRIVA TRAMITE AUTO-LOGIN
    const { state } = useLocation();
    let { paramsFromUrl } = state || {};

    // QUESTA ROBA MI SERVE E ARRIVA SE TRAMITE MAIL
    const uuidPackageFromUrl = getQueryParam(UUID_PACKAGE_PARAM_NAME);
    const uuidServicefromUrl = getQueryParam(UUID_SERVICE_PARAM_NAME);
    const uuidStudentFromUrl = getQueryParam(UUID_STUDENT_PARAM_NAME);
    // QUESTO MI SERVE IN /usersENTRAMBI I CASI
    const languageFromUrl = getQueryParam('lang');
    const mediumFromUrl = getQueryParam('medium');

    const [isLoading, setIsLoading] = useState(false);
    const [taxCode, setTaxCode] = React.useState<string>("");
    let navigate = useNavigate();

    const onTaxCodeChange = (e: any) => {
        const value = e.target.value;
        setTaxCode(value);
    };


    const checkTaxCode = async () => {
        setIsLoading(true);

        if (uuidPackageFromUrl &&
            uuidServicefromUrl &&
            uuidStudentFromUrl) {

            try {
                const response = await getByTaxCode(taxCode, uuidStudentFromUrl, uuidServicefromUrl);
                if (response.status >= 200 && response.status <= 299) {
                    paramsFromUrl = {
                        uuidPackageFromUrl: uuidPackageFromUrl,
                        uuidServicefromUrl: uuidServicefromUrl,
                        uuidStudentFromUrl: uuidStudentFromUrl
                    }
                    const dataToPass = response.data;

                    localStorage.removeItem('whereIsClicked');
                    return navigate('/test-motiva' + (mediumFromUrl ? ('?medium=' + mediumFromUrl) : ''), { state: { dataToPass, paramsFromUrl } });

                } else {

                    toast('Inserisci il codice fiscale corretto', {
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    console.log(response);
                    // console.log('1s');
                    // alert(response.data);
                    throw new Error(response.statusText);
                }
            } catch (e) {

                toast('Inserisci il tuo codice fiscale corretto', {
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                console.log(e);
                // alert(e);
                // console.log('1');

            }
        } else {
            alert("No data found in url");
            console.log('1sss');

        }
        setIsLoading(false);

    }

    var loginStyle = {};

    if (mediumFromUrl === 'app') {
        loginStyle = {
            minHeight: '100vh'
        };
    }


    return (<main className="LoginPage" style={loginStyle}>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
                marginTop: '5vh'
            }}
        />
        <div className='titleContainer'>
            <h1 className='mb-5'>
                Test di Orientamento Motiva
            </h1>

        </div>
        {/* <div className='text-center'>
            Il test di orientamento Motiva non è obbligatorio per ottenere la certificazione.
        </div> */}
        <div className='alignWithMediaQuery pt-3'>
            Per iniziare il test inserisci il tuo codice fiscale.
        </div>
        <div className="inputContainer textAlignCenter">

            <div className='mt-4'>
                <input type="text"
                    name=""
                    id=""
                    // placeholder={'Codice Fiscale'}
                    maxLength={20}
                    onChange={onTaxCodeChange}
                />
            </div>
            <div className='mt-4'>
                <Button variant="primary" className='bgNeonBlue' onClick={checkTaxCode}
                    disabled={isLoading}>
                    {
                        isLoading
                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : "Vai al test"
                    }
                </Button>

            </div>
        </div>
    </main>);
};

export default LoginPage;