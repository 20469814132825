import axios, { AxiosResponse } from "axios";

export const { BASE_URL } = window.env;
export const { TEST_MOTIVA_URL } = window.env;

export enum ServiceStatus {
    EXECUTING = "EXECUTING",
    COMPLETED = "COMPLETED",
}

const url_autologin = (studentUuid: string) => BASE_URL + '/api/v1/students/' + studentUuid + '/by_token';
const url_byTaxCode = (studentUuid: string) => BASE_URL + '/api/v1/students/' + studentUuid + '/by_tax_code';
const url_updateStudentEvents = () => BASE_URL + '/api/v1/event/';
const url_updateServiceStatus = (packageUuid: string, serviceUuid: string) => BASE_URL + '/api/v1/packages/' + packageUuid + '/services/' + serviceUuid + '/status';
const url_getMotivaLink = (packageUuid: string, serviceUuid: string) => BASE_URL + '/api/v1/packages/' + packageUuid + '/services/' + serviceUuid + '/motiva_link';
const url_sendMotivaPdf = (packageUuid: string, serviceUuid: string) => BASE_URL + '/api/v1/packages/' + packageUuid + '/services/' + serviceUuid + '/motiva_document';
const url_updateStudentChoosenProfessions = (packageUuid: string, serviceUuid: string) => BASE_URL + '/api/v1/packages/' + packageUuid + '/services/' + serviceUuid + '/professions';
const url_getProfessions = (packageUuid: string, serviceUuid: string) => BASE_URL + '/api/v1/packages/' + packageUuid + '/services/' + serviceUuid + '/professions';


export const getToken = async () => {
    return await axios(`${BASE_URL}/authentication/get_token`, {
        method: "GET",
        withCredentials: true
    });
}

const executeAxios = async (path: string, method: string, params: any = undefined, data: any = undefined, headers: any = undefined): Promise<AxiosResponse> => {
    try {
        return await axios(
            path,
            {
                method: method,
                withCredentials: true,
                params: params,
                data: data,
                headers: headers
            }
        );
    } catch (error: any) {
        return error.response;
    }
}

export const loginByToken = async (authToken: string, studentUuid: string, serviceUuid: string) => {


    return await executeAxios(
        url_autologin(studentUuid),
        "GET",
        {
            authToken: authToken,
            serviceId: serviceUuid,
        }
    );
}

export const getByTaxCode = async (taxCode: string, studentUuid: string, serviceUuid: string) => {
    return await executeAxios(
        url_byTaxCode(studentUuid),
        "POST",
        undefined,
        {
            taxCode: taxCode,
            serviceId: serviceUuid,
        }
    );
}

export const updateStudentEvent = async (studentUuid: string, packageUuid: string, serviceUuid: string, data: any) => {
    return await executeAxios(
        url_updateStudentEvents(),
        "POST",
        undefined,
        data,
        {
            'account-uuid': studentUuid
        }
    );
}

export const updateServiceStatus = async (studentUuid: string, packageUuid: string, serviceUuid: string, status: ServiceStatus) => {
    return await executeAxios(
        url_updateServiceStatus(packageUuid, serviceUuid),
        "PUT",
        undefined,
        {
            status: status
        },
        {
            'account-uuid': studentUuid
        });
}

export const getMotivaLink = async (studentUuid: string, packageUuid: string, serviceUuid: string) => {
    return await executeAxios(
        url_getMotivaLink(packageUuid, serviceUuid),
        "GET",
        {
            target: "_blank",
        },
        undefined,
        {
            'account-uuid': studentUuid

        });
}

export const uploadTestPdf = async (studentUuid: string, packageUuid: string, serviceUuid: string, formData: FormData) => {
    return await executeAxios(
        url_sendMotivaPdf(packageUuid, serviceUuid),
        "POST",
        undefined,
        formData,
        {
            'account-uuid': studentUuid,
            'Content-Type': 'multipart/form-data'
        }
    );
}

export const updateStudentChoosenProfessions = async (studentUuid: string, packageUuid: string, serviceUuid: string, professionsUuid: Array<string>) => {
    return await executeAxios(
        url_updateStudentChoosenProfessions(packageUuid, serviceUuid),
        "PUT",
        undefined,
        {
            professions: professionsUuid
        },
        {
            'account-uuid': studentUuid,
        }
    );
}

export const getProfessions = async (studentUuid: string, packageUuid: string, serviceUuid: string) => {
    return await executeAxios(
        url_getProfessions(packageUuid, serviceUuid),
        "GET",
        undefined,
        undefined,
        {
            'account-uuid': studentUuid
        });
}