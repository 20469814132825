import React from 'react';


export interface IDelayedTestPageProps { }

const DelayedTestPage: React.FunctionComponent<IDelayedTestPageProps> = (props) => {


    return (<main className="DelayedTestPage">

        <div className='text-center mt-5'>
            <h1>NON PERDERE QUESTA OCCASIONE!</h1><br />
            <div className='textAlignCenter'>
                <p>
                    Hai scelto di fare il test di orientamento in un secondo momento.<br />
                    Ti abbiamo inviato una e-mail con il link al test.
                </p>
                <small>Se non la ricevi, controlla anche nella cartella spam.</small>
            </div>
        </div>

    </main>);
};

export default DelayedTestPage;