import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import AuthRoute from './authRoutes/AuthRoute';
import Footer from './components/Footer';
import Header from './components/Header';
import AutoLoginPage from './pages/AutoLoginPage';
import DelayedTestPage from './pages/DelayedTestPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ProfessionsPage from './pages/ProfessionsPage';
import TestInBrowserPage from './pages/TestInBrowserPage';
import ThankYouPage from './pages/ThankYouPage';
import { getToken } from './utils/api';
import { getQueryParam } from './utils/utils';

export interface IApplicationProps { }


// const messagesInItalian = {
//     "login.title": "TEST DI ORIENTAMENTO MOTIVA",
//     "login.text": "Per iniziare il test inserisci il tuo codice fiscale",
//     "login.butt": "Vai al test",

//     "nowOrLater.title": "VUOI FARE IL TEST DI ORIENTAMENTO?",
//     "nowOrLater.subtitle": "Scegli cosa fare:",
//     "nowOrLater.firstLiTitle": "1.  Vai al Test",
//     "nowOrLater.firstLiText": "Se decidi di iniziare il test, dovrai portarlo obbligatoriamente a termine per richiedere la certificazione Habacus.",
//     "nowOrLater.secondLiTitle": "2. Vai alla certificazione",
//     "nowOrLater.secondLiText": "Potrai fare il test di orientamento in un secondo momento entro 30gg da oggi. In questo caso il test non è obbligatorio.",
//     "nowOrLater.nowButt": "Vai al Test",
//     "nowOrLater.laterButt": "Vai alla certificazione",

//     "mobileOrPc.title": "DOVE VUOI FARE IL TEST DI ORIENTAMENTO?",
//     "mobileOrPc.subtitle": "Scegli:",
//     "mobileOrPc.firstLiTitle": "1.  Resta su smartphone",
//     "mobileOrPc.firstLiText": "Inizia e svolgi il test dall’app di Habacus",
//     "mobileOrPc.secondLiTitle": "2. Vai su desktop",
//     "mobileOrPc.secondLiText": "Ti inviamo il link del test di orientamento via email",
//     "mobileOrPc.nowButt": "Inizia il Test",
//     "mobileOrPc.laterButt": "Invia mail",



//     "mobileOrPc": "",
//     "actual": "",



// }
// const messagesInEnglish = {
//     "login.title": "MOTIVA ORIENTATION TEST",
//     "login.text": "Per iniziare il test inserisci il tuo codice fiscale",
//     "login.butt": "Vai al test",

//     "nowOrLater.title": "DO YOU WANT TO TAKE THE ORIENTATION TEST?",
//     "nowOrLater.subtitle": "Make your choice:",
//     "nowOrLater.firstLiTitle": "1.	Go to the test",
//     "nowOrLater.firstLiText": "If you decide to start the test, you will need to complete it to apply for Habacus certification.",
//     "nowOrLater.secondLiTitle": "2.	Go to the certification",
//     "nowOrLater.secondLiText": "You can take the orientation test at a later time within 30 days from today. In this case the test is not mandatory.",
//     "nowOrLater.nowButt": "Go to the test",
//     "nowOrLater.laterButt": "Go to the certification",

//     "mobileOrPc.title": "WHERE DO YOU WANT TO TAKE THE ORIENTATION TEST?",
//     "mobileOrPc.subtitle": "You choose:",
//     "mobileOrPc.firstLiTitle": "1.	Stay on smartphone",
//     "mobileOrPc.firstLiText": "Start and take the test on the Habacus app",
//     "mobileOrPc.secondLiTitle": "2. Go to desktop",
//     "mobileOrPc.secondLiText": "We will send you the link of the orientation test by mail",
//     "mobileOrPc.nowButt": "Start the test",
//     "mobileOrPc.laterButt": "Send mail",

// }

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    // .env
    // BASE_URL = "http://localhost:8000"
    // # BASE_URL = "https://preprod.hpp.habacus.com"
    // TEST_MOTIVA_URL = "http://test-motiva.com" 
    // # TEST_MOTIVA_URL = "https://start-motiva-test-vtmgllutxq-ew.a.run.app/startTest"

    const mediumFromUrl = getQueryParam('medium');
    const [tokenReceived, setTokenReceived] = useState(false);

    const waitForToken = async () => {
        try {
            await getToken();
        } catch (e) {
            console.log(e);
        } finally {
            setTokenReceived(true);
        }
    };
    useEffect(() => {
        waitForToken();
    }, [])

    if (tokenReceived === false) {
        return null;
    }

    const isInApp = () => {
        return mediumFromUrl === 'app';
    }

    return (<div className='App'>
        {
            isInApp() ? null : <Header />
        }
        <BrowserRouter>
            <Routes>
                <Route path="/test-motiva" element={
                    <AuthRoute>
                        <HomePage />
                    </AuthRoute>
                }
                />
                <Route path="/professions" element={
                    <AuthRoute>
                        <ProfessionsPage />
                    </AuthRoute>
                }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/auto-login" element={<AutoLoginPage />} />
                <Route path="/thank-you" element={<ThankYouPage />} />
                <Route path="/test-rimandato" element={<DelayedTestPage />} />
                <Route path="/test-in-browser" element={<TestInBrowserPage />} />
                {/* <Route path="/thank-you" element={<ThankYouPage />} /> */}
            </Routes>
        </BrowserRouter>
        {
            isInApp() ? null : <Footer />
        }
    </div>
    );
};

export default App;
