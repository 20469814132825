import React, { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { loginByToken } from '../utils/api';
import { AUTH_TOKEN_PARAM_NAME, getQueryParam, UUID_PACKAGE_PARAM_NAME, UUID_SERVICE_PARAM_NAME, UUID_STUDENT_PARAM_NAME } from '../utils/utils';
export interface IAutoLoginPageProps { }

const AutoLoginPage: React.FunctionComponent<IAutoLoginPageProps> = (props) => {
    // http://localhost:3000/auto-login?package_id=zxc&service_id=qwert&student_id=c3179682-a03b-48b0-9be6-3b05c0cfad4f

    // http://localhost:3000/auto-login?package_id=zxc&service_id=qwert&student_id=c3179682-a03b-48b0-9be6-3b05c0cfad4f&auth_token=123

    // 1234567890123456

    const uuidPackageFromUrl = getQueryParam(UUID_PACKAGE_PARAM_NAME);
    const uuidServicefromUrl = getQueryParam(UUID_SERVICE_PARAM_NAME);
    const uuidStudentFromUrl = getQueryParam(UUID_STUDENT_PARAM_NAME);
    const authTokenfromUrl = getQueryParam(AUTH_TOKEN_PARAM_NAME);

    const mediumFromUrl = getQueryParam('medium');

    const navigate = useNavigate();

    const performAutoLogin = async () => {

        const paramsFromUrl = {
            uuidPackageFromUrl: uuidPackageFromUrl,
            uuidServicefromUrl: uuidServicefromUrl,
            uuidStudentFromUrl: uuidStudentFromUrl
        }

        if (uuidPackageFromUrl &&
            uuidServicefromUrl &&
            uuidStudentFromUrl &&
            authTokenfromUrl) {

            try {
                const response = await loginByToken(authTokenfromUrl, uuidStudentFromUrl, uuidServicefromUrl);
                if (response.status >= 200 && response.status <= 299) {
                    const dataToPass = response.data;
                    localStorage.removeItem('whereIsClicked');
                    return navigate('/test-motiva' + (mediumFromUrl ? ('?medium=' + mediumFromUrl) : ''), { state: { dataToPass, paramsFromUrl } });
                } else {
                    console.log(response);
                    throw new Error(response.statusText);
                }
            } catch (e) {
                console.log(e);
                return navigate('/login', { state: { paramsFromUrl } });
            }
        } else {

            return navigate('/login', { state: { paramsFromUrl } });
        }
    }

    useEffect(() => {
        performAutoLogin();

    }, []);

    return (<main className="AutoLoginPage flexCenter">
        <div className='textAlignCenter'>
            <Spinner animation="grow" variant="primary"></Spinner>

            <h1>Caricamento...</h1>
        </div>
    </main>);
};

export default AutoLoginPage;