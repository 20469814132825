import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import greenCheck from '../assets/icons8-checkmark-150.png';
import external_link from '../assets/icons8-link-esterno-squadrato-96.png';
import floppy from '../assets/icons8-salva-100.png';
import { ServiceStatus, getProfessions, updateServiceStatus, updateStudentChoosenProfessions, updateStudentEvent } from '../utils/api';
import { PEARSON_COURSE, getQueryParam } from '../utils/utils';

export interface IProfessionsPageProps { }

const ProfessionsPage: React.FunctionComponent<IProfessionsPageProps> = (props) => {


    let navigate = useNavigate();
    const { state } = useLocation();
    const { dataToPass } = state || {};
    const { paramsFromUrl } = state || {};

    const [isLoading, setIsLoading] = useState(false);

    const languageFromUrl = getQueryParam('lang');
    const mediumFromUrl = getQueryParam('medium');

    // const updateStudentChoosenProfessionsUrl = BASE_URL + '/api/v1/student_choosen_profession';

    // const languageFromUrl = 'it';
    // const mediumFromUrl = 'app';
    // const mediumFromUrl = 'web';
    const goInApp = async () => {
        setIsLoading(true);

        try {
            // window.location.href = 'https://habacuspreprod.page.link/qbvQ';
            window.location.href = 'https://mobile.habacus.com/tHrB';
        } catch (e) {
            console.log(e);
            window.location.href = 'https://appdownload.habacus.com';
        }
        finally {
            setIsLoading(false);
        }
    }


    // const updateStudentEventsUrl = BASE_URL + '/api/v1/event/';
    // const updateServiceStatusUrl = BASE_URL + '/api/v1/packages/' + paramsFromUrl.uuidPackageFromUrl + '/services/' + paramsFromUrl.uuidServicefromUrl + '/status';
    // const getMotivaLinkUrl = BASE_URL + '/api/v1/packages/' + paramsFromUrl.uuidPackageFromUrl + '/services/' + paramsFromUrl.uuidServicefromUrl + '/motiva_link';
    // const sendMotivaPdfUrl = BASE_URL + '/api/v1/packages/' + paramsFromUrl.uuidPackageFromUrl + '/services/' + paramsFromUrl.uuidServicefromUrl + '/motiva_document';

    const [professions, setProfessions] = useState<Array<any>>([]);
    const [profession1, setProfession1] = useState('');
    const [profession2, setProfession2] = useState('');
    const [profession3, setProfession3] = useState('');
    const [isRenderChoiceVisible, setIsRenderChoiceVisible] = useState(true);
    const [isRenderChoiceDoneVisible, setIsRenderChoiceDoneVisible] = useState(false);

    const downloadProfessions = async () => {
        const response = await getProfessions(
            paramsFromUrl.uuidStudentFromUrl,
            paramsFromUrl.uuidPackageFromUrl,
            paramsFromUrl.uuidServicefromUrl,
        );
        console.log(response.data.professions);
        console.log(response.data.professions.length);
        for (var i = 0; i < response.data.professions.length; i++) {
            console.log(response.data.professions[i]['id']);
            console.log(response.data.professions[i]['title']);
            response.data.professions[i]['value'] = response.data.professions[i]['id']
            response.data.professions[i]['label'] = response.data.professions[i]['title']
            console.log(response.data.professions[i]['value']);
            console.log(response.data.professions[i]['label']);
        }

        setProfessions(response.data.professions);
        console.log(response.data.professions);
    }

    useEffect(() => {
        downloadProfessions();
    }, []);

    const getProfession1Value = () => {
        for (let index = 0; index < professions.length; index++) {
            console.log(professions[index].value);
            console.log(profession1);

            if (professions[index].id === profession1) {
                return professions[index];
            }
        }
        return null
    }
    const handleChangeProfession1 = (selectedOption: any) => {

        setProfession1(selectedOption?.value);
        console.log(selectedOption?.value);
        console.log(selectedOption?.label);
        console.log(profession1);
    };
    //2
    const getProfession2Value = () => {
        for (let index = 0; index < professions.length; index++) {

            if (professions[index].value === profession2) {
                return professions[index];
            }
        }
        return null
    }
    const handleChangeProfession2 = (selectedOption: any) => {
        setProfession2(selectedOption?.value);
    };

    //3
    const getProfession3Value = () => {
        for (let index = 0; index < professions.length; index++) {

            if (professions[index].value === profession3) {
                return professions[index];
            }
        }
        return null
    }
    const handleChangeProfession3 = (selectedOption: any) => {
        setProfession3(selectedOption?.value);
    };


    var homeStyle = {
    };

    if (mediumFromUrl === 'app') {
        homeStyle = {
            minHeight: '100vh'
        };
    }


    const animatedComponents = makeAnimated();

    const handleCloseAndSendData = async () => {
        console.log('sentdat!');

        //API PER MANDARE LE 3 PROFESSIONI
        setIsLoading(true);

        setIsRenderChoiceVisible(false);
        setIsRenderChoiceDoneVisible(true);

        try {
            let resp = await updateStudentChoosenProfessions(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                [profession1, profession2, profession3]);

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }


            resp = await updateServiceStatus(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                ServiceStatus.COMPLETED);

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }




            resp = await updateStudentEvent(
                paramsFromUrl.uuidStudentFromUrl,
                paramsFromUrl.uuidPackageFromUrl,
                paramsFromUrl.uuidServicefromUrl,
                {
                    category: dataToPass.package.name,
                    label: PEARSON_COURSE,
                    action: "CHOICE",
                    type: "PROFESSIONS"
                });

            if (resp.status < 200 || resp.status > 299) {
                console.log(resp);
                alert(JSON.stringify(resp));
                throw new Error(resp.statusText);
            }



        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    }
    const checkForm = () => {
        console.log('checkform');
        console.log('profession1: ' + profession1);
        console.log('profession2: ' + profession2);
        console.log('profession3: ' + profession3);


        if (!profession1 || !profession2 || !profession3) {
            toast('Inserisci tutte e 3 le professioni consigliate', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else if (profession1 === profession2 || profession2 === profession3 || profession1 === profession3) {
            toast('Le professioni consigliate devono essere diverse tra loro', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        } else {
            handleCloseAndSendData()
        }
    }

    const renderChoiceDone = () => {
        return <div className="choiceProfessionsDone mt-5">

            <div className='flexCenter mb-3'>
                <img src={greenCheck} alt="" />
            </div>
            <div className='flexCenter mb-3'>
                Scelta effettuata correttamente.
            </div>
            <div className='flexCenter'>
                <Button id="goInApp" className="blueNeonButt customButt mt-5" onClick={goInApp} disabled={isLoading}>
                    <div className='flexButt'>
                        <img src={external_link} alt="" />
                        <div className='saveAndGo'>
                            TORNA IN APP
                        </div>
                    </div>
                </Button>

            </div>
        </div>
    }

    const renderChoice = () => {
        return <>
            <h1 className='mb-4'>Scegli le tue professioni</h1>
            <p className='mb-4 alignWithMediaQuery'>
                Scegli 3 professioni di quelle risultate dal test di orientamento su cui calcolare i percorsi di studio più coerenti
            </p>
            <div className="choiceProfessions">
                <Form>
                    <div className='row mb-3 '>
                        <Form.Group className="col col-md-6 offset-md-3 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}
                            <Form.Label className='mb-0 text-uppercase'>Prima scelta</Form.Label>
                            <Select
                                options={professions}
                                components={animatedComponents}
                                isClearable={true}
                                placeholder={'... seleziona professione'}
                                value={getProfession1Value()}
                                onChange={handleChangeProfession1}
                            />
                        </Form.Group>
                    </div>
                    <div className='row mb-3 '>
                        <Form.Group className="col col-md-6 offset-md-3 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}

                            <Form.Label className='mb-0 text-uppercase'>Seconda scelta</Form.Label>
                            <Select
                                options={professions}
                                components={animatedComponents}
                                isClearable={true}
                                placeholder={'... seleziona professione'}
                                value={getProfession2Value()}
                                onChange={handleChangeProfession2}
                            />
                        </Form.Group>
                    </div>
                    <div className='row mb-3 '>
                        <Form.Group className="col col-md-6 offset-md-3 mb-3 mb-md-0" > {/* controlId="formBasicDate" */}

                            <Form.Label className='mb-0 text-uppercase'>Terza scelta</Form.Label>
                            <Select
                                options={professions}
                                components={animatedComponents}
                                isClearable={true}
                                placeholder={'... seleziona professione'}
                                value={getProfession3Value()}
                                onChange={handleChangeProfession3}
                            />
                        </Form.Group>
                    </div>
                    <div className='row mb-5 '>
                        {/* <Button className="purpleButt" onClick={handleShow}> */}
                        <div className=' col col-md-6 offset-md-3'>
                            <Button className="blueNeonButt customButt w-100" onClick={checkForm} disabled={isLoading}>
                                <div className='flexCenter'>

                                    {
                                        isLoading
                                            ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            : <>
                                                <img src={floppy} alt="" />

                                                <div className='saveAndGo '>
                                                    <div>CONFERMA</div>
                                                </div>
                                            </>
                                    }


                                </div>
                            </Button>

                        </div>

                    </div>
                </Form>

            </div>
        </>
    }
    return (<main className="ProfessionsPage" style={homeStyle}>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
                marginTop: '5vh'
            }}
        />
        {/* <h1 className='mb-5'>Scegli le tue professioni</h1> */}

        {isRenderChoiceVisible ? renderChoice() : ''}
        {isRenderChoiceDoneVisible ? renderChoiceDone() : ''}

    </main>);
};

export default ProfessionsPage;