import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IAuthRouteProps {
    children: any;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
    const { children } = props;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     const AuthCheck = onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             setLoading(false);
    //         } else {
    //             console.log('unauthorized');
    //             navigate('/login');
    //         }
    //     });

    //     return () => AuthCheck();
    // }, [auth]);

    if (loading) return <p>loading ...</p>;

    return <>{children}</>;
};

export default AuthRoute;